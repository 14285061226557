import {
  MdOutlinePower,
  MdFormatListBulleted,
  MdNotifications,
  MdAccountCircle,
  MdBusiness,
  MdSettings,
  MdOutlineLocalShipping,
  MdOutlineEventNote,
} from 'react-icons/md'
import account from '@src/assets/icons/account.svg'
import mail from '@src/assets/icons/mail.svg'
import bookings from '@src/assets/icons/bookings.svg'

export const ourServicesMenuItems = [
  {
    label: "Regulated Waste Disposal",
    link: "https://app.getdimension.com/hazardous-waste-disposal?keyword=regulated",
    isOutsideLink: true,
    column: 1,
    subLinks: [
      {
        label: "Oil Container Pickup",
        link: "https://app.getdimension.com/oil-recycling?keyword=oil",
        isOutsideLink: true,
      },
      {
        label: "Oil Pumping Service",
        link: "https://app.getdimension.com/oil-recycling?keyword=oil",
        isOutsideLink: true,
      },
      {
        label: "Paint Recycling and Disposal",
        link: "https://app.getdimension.com/hazardous-waste-disposal?keyword=paint",
        isOutsideLink: true,
      },
      {
        label: "Other Regulated Waste Disposal",
        link: "https://app.getdimension.com/hazardous-waste-disposal?keyword=regulated",
        isOutsideLink: true,
      },
    ],
  },
  {
    label: "Hazardous Waste Services",
    link: "https://app.getdimension.com/hazardous-waste-disposal?keyword=hazardous",
    isOutsideLink: true,
    column: 1,
    subLinks: [
      {
        label: "Flammable Waste Disposal",
        link: "https://app.getdimension.com/hazardous-waste-disposal?keyword=flammable",
        isOutsideLink: true,
      },
      {
        label: "Corrosive Waste Disposal",
        link: "https://app.getdimension.com/hazardous-waste-disposal?keyword=corrosive",
        isOutsideLink: true,
      },
      {
        label: "Toxic Waste Disposal",
        link: "https://app.getdimension.com/hazardous-waste-disposal?keyword=toxic",
        isOutsideLink: true,
      },
      {
        label: "Labpack Services",
        link: "https://app.getdimension.com/hazardous-waste-disposal?keyword=labpack",
        isOutsideLink: true,
      },
      {
        label: "Medical Waste Disposal",
        link: "https://app.getdimension.com/hazardous-waste-disposal?keyword=medical",
        isOutsideLink: true,
      },
      {
        label: "Sharps Disposal",
        link: "https://app.getdimension.com/hazardous-waste-disposal?keyword=sharps",
        isOutsideLink: true,
      },
      {
        label: 'Fire Debris Removal',
        link: 'https://app.getdimension.com/fire-debris-removal?keyword=fire%20debris',
        isOutsideLink: true,
      },
      {
        label: "Other Hazardous Waste",
        link: "https://app.getdimension.com/hazardous-waste-disposal?keyword=hazardous",
        isOutsideLink: true,
      },
    ],
  },
  {
    label: 'Sanitizer and PPE Recycling',
    link: 'https://app.getdimension.com/hand-sanitizer-disposal?keyword=sanitizer%20disposal',
    isOutsideLink: true,
    column: 1,
    subLinks: [
      {
        label: 'Hand Sanitizer Disposal',
        link: 'https://app.getdimension.com/hand-sanitizer-disposal?keyword=sanitizer%20disposal',
        isOutsideLink: true,
      },
      {
        label: 'Hand Sanitizer Recycling',
        link: 'https://app.getdimension.com/hand-sanitizer-disposal?keyword=sanitizer%20recycle',
        isOutsideLink: true,
      },
      {
        label: 'PPE Disposal and Recycling',
        link: 'https://app.getdimension.com/hand-sanitizer-disposal?keyword=ppe',
        isOutsideLink: true,
      },
    ],
  },
  {
    label: 'Beverage and Alcohol Disposal',
    link: 'https://app.getdimension.com/junk-disposal?keyword=beverage',
    isOutsideLink: true,
    column: 1,
    subLinks: [
      {
        label: 'Alcoholic Beverages',
        link: 'https://app.getdimension.com/junk-disposal?keyword=alcohol',
        isOutsideLink: true,
      },
      {
        label: 'Sugary Beverages',
        link: 'https://app.getdimension.com/junk-disposal?keyword=sugary',
        isOutsideLink: true,
      },
      {
        label: 'Milk Beverages and Dairy',
        link: 'https://app.getdimension.com/junk-disposal?keyword=milk',
        isOutsideLink: true,
      },
      {
        label: 'Industrial Alcohol',
        link: 'https://app.getdimension.com/junk-disposal?keyword=alcohol',
        isOutsideLink: true,
      },
    ],
  },
  {
    label: 'Junk Removal Services',
    link: 'https://app.getdimension.com/junk-disposal?keyword=junk',
    isOutsideLink: true,
    column: 2,
    subLinks: [
      {
        label: 'Mattress Removal',
        link: 'https://app.getdimension.com/junk-disposal?keyword=mattress',
        isOutsideLink: true,
      },
      {
        label: 'Appliance Removal',
        link: 'https://app.getdimension.com/junk-disposal?keyword=appliance',
        isOutsideLink: true,
      },
      {
        label: 'Furniture Removal',
        link: 'https://app.getdimension.com/junk-disposal?keyword=furniture',
        isOutsideLink: true,
      },
      {
        label: 'Piano Removal',
        link: 'https://app.getdimension.com/junk-disposal?keyword=piano',
        isOutsideLink: true,
      },
      {
        label: 'TV and Electronics Removal',
        link: 'https://app.getdimension.com/junk-disposal?keyword=tv',
        isOutsideLink: true,
      },
      {
        label: 'Yard Debris Removal',
        link: 'https://app.getdimension.com/junk-disposal?keyword=yard',
        isOutsideLink: true,
      },
      {
        label: 'Other Junk Removal',
        link: 'https://app.getdimension.com/junk-disposal?keyword=junk',
        isOutsideLink: true,
      },
    ],
  },
  {
    label: 'Cardboard Recycling',
    link: 'https://app.getdimension.com/junk-disposal?keyword=cardboard',
    isOutsideLink: true,
    column: 2,
  },
  {
    label: 'Dumpster Rentals',
    link: 'https://app.getdimension.com/junk-disposal?keyword=dumpster',
    isOutsideLink: true,
    column: 2,
  },
  {
    label: 'Pallet Disposal',
    link: 'https://app.getdimension.com/junk-disposal?keyword=pallet',
    isOutsideLink: true,
    column: 2,
  },
  {
    label: 'General Waste Disposal',
    link: 'https://app.getdimension.com/junk-disposal?keyword=waste',
    isOutsideLink: true,
    column: 2,
  },
  {
    label: 'Solar Panel Services',
    link: 'https://app.getdimension.com/solar-panel-disposal?keyword=solar',
    isOutsideLink: true,
    column: 3,
    subLinks: [
      {
        label: 'Solar Panel Disposal',
        link: 'https://app.getdimension.com/solar-panel-disposal?keyword=solar',
        isOutsideLink: true,
      },
      {
        label: 'Solar Panel Recycling',
        link: 'https://app.getdimension.com/solar-panel-disposal?keyword=solar%20recycling',
        isOutsideLink: true,
      },
      {
        label: 'Sell Solar Panels',
        link: 'https://app.getdimension.com/solar-panel-sell',
        isOutsideLink: true,
      },
      {
        label: 'Solar Decommissioning',
        link: 'https://app.getdimension.com/commercial-solar-panel-uninstallation-and-disposal?keyword=commercial%20solar',
        isOutsideLink: true,
      },
    ],
  },
  {
    label: 'Recurring Services',
    link: 'https://app.getdimension.com/junk-disposal?keyword=recurring',
    isOutsideLink: true,
    column: 3,
    subLinks: [
      {
        label: 'Commercial Waste Disposal',
        link: 'https://app.getdimension.com/junk-disposal?keyword=recurring%20commercial',
        isOutsideLink: true,
      },
      {
        label: 'Residential Trash and Recycling',
        link: 'https://app.getdimension.com/junk-disposal?keyword=recurring%20residential',
        isOutsideLink: true,
      },
      {
        label: 'Dumpster Swaps',
        link: 'https://app.getdimension.com/junk-disposal?keyword=dumpster',
        isOutsideLink: true,
      },
    ],
  },
  {
    label: 'Property Cleanouts',
    link: 'https://app.getdimension.com/junk-disposal?keyword=cleanouts',
    isOutsideLink: true,
    column: 3,
    subLinks: [
      {
        label: 'Commercial Office Cleanout',
        link: 'https://app.getdimension.com/junk-disposal?keyword=commercial%20cleanout',
        isOutsideLink: true,
      },
      {
        label: 'Residential Cleanout',
        link: 'https://app.getdimension.com/junk-disposal?keyword=residential%20cleanout',
        isOutsideLink: true,
      },
    ],
  },
  {
    label: 'Composting Services',
    link: 'https://app.getdimension.com/compost-disposal',
    isOutsideLink: true,
    column: 3,
    subLinks: [
      {
        label: 'Commercial Composting',
        link: 'https://app.getdimension.com/compost-disposal',
        isOutsideLink: true,
      },
      {
        label: 'Commercial Composting in Austin',
        link: 'https://app.getdimension.com/compost-disposal-austin',
        isOutsideLink: true,
      },
    ],
  },
]

export const businessMenuItems = [
  {
    label: 'Dimension for Business',
    link: '/b2b-solutions',
  },
  {
    label: 'Case Studies',
    link: '/case-studies',
  },
  {
    label: 'Business Solutions',
    link: '/business',
  },
]

export const providersMenuItems = [
  {
    label: 'Become a Provider',
    link: '/provider_portal',
  },
  {
    label: 'Provider Portal',
    link: '/provider_portal',
    id: 'provider_portal_direct',
  },
  {
    label: 'Provider Agreement',
    link: '/provider-agreement',
    isRedirect: true,
  },
  {
    label: 'Service Provider Policy',
    link: '/service-provider-policy',
    isRedirect: true,
  },
]

export const aboutUsMenuItems = [
  {
    label: 'About Dimension',
    link: '/about_us',
  },
  {
    label: 'Careers',
    link: 'https://jobs.lever.co/getdimension',
    isOutsideLink: true,
  },
  {
    label: 'Blog',
    link: '/blog',
  },
  {
    label: 'Company News',
    link: '/in-the-news',
  },
]

export const aboutUsMenuItemsCustomer = [
  {
    label: 'About Dimension',
    link: '/about_us',
  },
  {
    label: 'Blog',
    link: '/blog',
  },
  {
    label: 'Company News',
    link: '/in-the-news',
  },
]

export const sustainabilityItems = [
  {
    label: 'Sustainable Solutions',
    link: '/sustainability',
  },
  {
    label: 'How to Recycle',
    link: '/recycling',
  },
  {
    label: 'Waste Streams',
    link: '/materials',
  },
  {
    label: 'Sanitizer and PPE',
    link: 'https://app.getdimension.com/sanitizer-disposal',
    isOutsideLink: true,
  },
  {
    label: 'Pallet Disposal',
    link: 'https://app.getdimension.com/pallet-disposal',
    isOutsideLink: true,
  },
  {
    label: 'Hazardous Waste',
    link: 'https://app.getdimension.com/hazardous-waste-disposal',
    isOutsideLink: true,
  },
  {
    label: 'Solar Panels',
    link: 'https://app.getdimension.com/solar-panel-disposal',
    isOutsideLink: true,
  },
]

export const supportMenuItems = [
  {
    label: 'Help Center',
    link: 'https://support.getdimension.com/',
    isOutsideLink: true,
  },
  {
    label: 'Contact us',
    link: '',
  },
]

export const loggedInCustomerSupportMenuItems = [
  {
    label: 'Help Center',
    link: 'https://support.getdimension.com/en_us/categories/customer-faq-SkghOgbbi',
    isOutsideLink: true,
  },
  {
    label: 'Contact us',
    link: '',
  },
  {
    label: 'Terms of Service',
    link: '/terms',
    isRedirect: true,
  },
  {
    label: 'Privacy Policy',
    link: '/privacy-policy',
    isRedirect: true,
  },
]

export const loggedInSupportItems = [
  {
    label: 'Help Center',
    link: 'https://support.getdimension.com/en_us/categories/provider-faq-HkPjq9kZi',
    isOutsideLink: true,
  },
  {
    label: 'Contact us',
    link: '',
  },
  {
    label: 'Service Provider Policy',
    link: '/service-provider-policy',
    isRedirect: true,
  },
  {
    label: 'Provider Agreement',
    link: '/provider-agreement',
    isRedirect: true,
  },
]

export const headerMenu = {
  headerMenu: [
    {
      label: 'Our Services',
      menuItems: ourServicesMenuItems,
      link: null,
    },
    {
      label: 'Business',
      menuItems: businessMenuItems,
      link: null,
    },
    {
      label: 'Providers',
      menuItems: providersMenuItems,
      link: null,
    },
    {
      label: 'Sustainability',
      menuItems: sustainabilityItems,
      link: null,
    },
    {
      label: 'About Us',
      menuItems: aboutUsMenuItems,
      link: null,
    },
    {
      label: 'Support',
      menuItems: supportMenuItems,
      link: null,
    },
  ],
  providerHeaderMenu: [
    {
      label: 'Provider Portal',
      menuItems: [],
      link: '/provider_portal',
    },
    {
      label: 'Support',
      menuItems: loggedInSupportItems,
      link: null,
    },
  ],
  customerHeaderMenu: [
    {
      label: 'Business',
      menuItems: businessMenuItems,
      link: null,
    },
    {
      label: 'Sustainability',
      menuItems: sustainabilityItems,
      link: null,
    },
    {
      label: 'About Us',
      menuItems: aboutUsMenuItemsCustomer,
      link: null,
    },
    {
      label: 'Support',
      menuItems: loggedInSupportItems,
      link: null,
    },
  ],
}

export const accountMenuItems = (profile?: any, isMobile?: boolean) => {
  return {
    providerSideNavLinks: [
      {
        icon: <MdFormatListBulleted color='primary' />,
        label: 'Get Tasks',
        link: '/get_tasks',
        static: false,
        hidden: !isMobile || (!!profile?.approvalState && profile.approvalState !== 'complete'),
      },
      {
        icon: <MdOutlineEventNote color='primary' />,
        label: 'My Tasks',
        link: '/tasks',
        static: false,
        hidden:
          (!!profile?.approvalState && profile.approvalState !== 'complete') || profile?.isBanned,
      },
      {
        icon: <MdFormatListBulleted color='primary' />,
        label: 'Bid On Tasks',
        link: '/get_bids',
        static: false,
        hidden: !isMobile || (!!profile?.approvalState && profile.approvalState !== 'complete'),
      },
      {
        icon: <MdOutlineEventNote color='primary' />,
        label: 'My Bids',
        link: '/bids',
        static: false,
        hidden:
          (!!profile?.approvalState && profile.approvalState !== 'complete') || profile?.isBanned,
      },
      {
        icon: <MdAccountCircle color='primary' />,
        label: 'Account',
        link: '/account',
        static: false,
      },
      {
        icon: <MdNotifications color='primary' />,
        label: 'Alert Settings',
        link: '/alerts',
        static: false,
        hidden: profile?.isBanned,
      },
      {
        icon: <MdOutlineLocalShipping color='primary' />,
        label: 'Vehicle Info',
        link: '/vehicle_info',
        static: false,
        hidden: profile?.isBanned,
      },
    ],
    customerSideNavLinks: [
      {
        icon: <MdBusiness color='primary' />,
        label: 'Edit Company',
        link: '/company',
        static: false,
        isBusiness: true,
        hidden: true,
      },
      {
        icon: <MdOutlineEventNote color='primary' />,
        label: 'My Bookings',
        link: '/bookings',
        static: false,
      },
      {
        icon: <MdAccountCircle color='primary' />,
        label: 'Account',
        link: '/account',
        static: false,
      },
      {
        icon: <MdSettings color='primary' />,
        label: 'SMS and Email settings',
        link: '/alerts_sms_email',
        static: false,
      },
      {
        icon: <MdOutlinePower color='primary' />,
        label: 'Integrations',
        link: '/integrations',
        static: false,
        isIntegrations: true,
        hidden: !profile?.showIntegrations,
      },
      // {
      //   icon: <PermPhoneMsgOutlinedIcon color="primary" />,
      //   label: "Contact us",
      //   link: "/help",
      //   static: false,
      // },
    ],
  }
}

export const mobileAccountItems = [
  {
    label: 'Account',
    link: '/account',
    icon: account,
  },
  {
    label: 'My Bookings',
    link: '/bookings',
    icon: bookings,
  },
  {
    label: 'SMS and Email Settings',
    link: '/alerts_sms_email',
    icon: mail,
  },
]
